import React, { useEffect, useState } from "react";
import { Modal, Form, FormControl, FormLabel, Spinner } from "react-bootstrap";
import closebutton from "../../assets/images/multi-card-approval/x.svg";
import deleteicon from "../../assets/images/multi-card-approval/delete-icon.svg";
import editicon from "../../assets/images/multi-card-approval/edit-icon.svg";
import PendingIcon from "../../assets/images/multi-card-approval/pending_icon.svg";
import ApproveIcon from "../../assets/images/multi-card-approval/approveIcon.svg";
import RejectIcon from "../../assets/images/multi-card-approval/rejectedIcon.svg";
import Select from "react-select";
import { postData } from "../../api";
import { toast } from "react-toastify";
import { autocloseTiming } from "../../api/regex";

export default function HolidayModal(props) {
  const {
    holidayModal,
    setHolidayModal,
    handleClose,
    holidayIndividualdata,
    handleHolidayDelModalOpen,
    setEditAddEntry,
    editAddEntry,
    editModalHolidayData,
    handleHolidayEditOnChange,
    row,
    groupId,
    policyOptions,
    setLoading,
  } = props;

  const [btnDelLoading, setBtnDelLoading] = useState(false);
  const [apiHoursError, setApiHoursError] = useState("");
  const [isFutureDate, setIsFutureDate] = useState(false);

  useEffect(() => {
    const today = new Date();
    const holidayDate = new Date(holidayIndividualdata.date);

    if (holidayDate <= today) {
      setIsFutureDate(true);
    } else {
      setIsFutureDate(false);
    }
  }, [holidayIndividualdata]);

  const validateHoursFormat = (hours) => {
    const regex = /^\d{2}h \d{2}m$/;
    return regex.test(hours);
  };
  const extractHours = (hours) => {
    const [h] = hours.split(" ");
    return parseInt(h);
  };
  const handleHolidayUpdate = async () => {
    // if (!validateHoursFormat(editModalHolidayData?.totalHours)) {
    //   setApiHoursError("Invalid hours format. Please use 'hh:mm' format.");
    //   handleHolidayEditOnChange({ target: { value: "" } }, "totalHours");
    //   return;
    // }
    // setApiHoursError("");
    setBtnDelLoading(true);
    let holidayObj = {
      pto_id: holidayIndividualdata?.id,
      user_id: holidayIndividualdata?.user_id,
      hours: editModalHolidayData?.totalHours,
    };
    const res = await postData("holiday_pto", {}, holidayObj);
    try {
      if (res?.status === true) {
        setBtnDelLoading(false);
        setHolidayModal(false);
        toast.success(res?.message, {
          theme: "colored",
          autoClose: 2000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        setEditAddEntry(false);
      } else {
        setBtnDelLoading(false);
        toast.error(res?.message, {
          theme: "colored",
          autoClose: autocloseTiming,
        });
        setEditAddEntry(false);
        setHolidayModal(false);
      }
    } catch (err) {
      console.log(err);
      setBtnDelLoading(false);
      setEditAddEntry(false);
      setHolidayModal(false);
    }
  };
  return (
    <div className="details-modal-wrapper">
      <Modal
        show={holidayModal}
        onHide={handleClose}
        className="modal-detail-wrapper"
      >
        <Modal.Header className="modal-header-wrapper">
          <Modal.Title>
            <h4>
              {!editAddEntry ? "View holiday record" : "EDIT HOLIDAY record"}
            </h4>
          </Modal.Title>
          <div className="modal-close" onClick={handleClose}>
            <img src={closebutton} alt="closebutton" />
          </div>
        </Modal.Header>
        <Modal.Body className="modal-body-wrapper">
          <div className="status-flex-container">
            <p className="day">{holidayIndividualdata?.date_label}</p>
            {isFutureDate || groupId === 5 || groupId === 8 ? null : (
              <>
                {!editAddEntry && (
                  <div className="delete-edit-wrapper">
                    {holidayIndividualdata.type === "empty" ? null : (
                      <img
                        src={deleteicon}
                        alt="delete-icon"
                        onClick={() =>
                          handleHolidayDelModalOpen(
                            `Are you sure you want to delete ${row?.name} time card for ${holidayIndividualdata?.date_label}?`
                          )
                        }
                      />
                    )}
                    <>
                      {holidayIndividualdata.status === "Denied" ? null : (
                        <img
                          src={editicon}
                          alt="edit-icon"
                          onClick={() => setEditAddEntry(true)}
                        />
                      )}
                    </>
                  </div>
                )}
              </>
            )}
          </div>{" "}
          <div className="status-detail-wrapper">
            <img
              src={
                holidayIndividualdata?.status === "Pending"
                  ? PendingIcon
                  : holidayIndividualdata?.status === "Approved"
                  ? ApproveIcon
                  : RejectIcon
              }
              alt="pending-icon"
            />{" "}
            <p>{holidayIndividualdata?.status}</p>
          </div>
          <div className="form-wrapper">
            <Form>
              <div className="total-hours">
                <FormLabel>Hours</FormLabel>
                <select
                  className="form-control"
                  disabled={!editAddEntry}
                  value={editModalHolidayData?.totalHours?.slice(0, 3)}
                  onChange={(e) => handleHolidayEditOnChange(e, "totalHours")}
                >
                  {[...Array(12)].map((_, index) => {
                    const formattedHour =
                      (index + 1).toString().padStart(2, "0") + "h";
                    return (
                      <option key={index} value={formattedHour}>
                        {formattedHour}
                      </option>
                    );
                  })}
                </select>
              </div>
              {groupId === 5 || groupId === 8 ? null : (
                <div className="category">
                  <FormLabel>Category</FormLabel>
                  <Select
                    placeholder="Select Category"
                    isDisabled={true}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    value={
                      holidayIndividualdata?.category
                        ? {
                            value: holidayIndividualdata.category,
                            label: holidayIndividualdata.category,
                          }
                        : null
                    }
                    options={policyOptions.map((option) => ({
                      value: option.id,
                      label: option.name,
                    }))}
                  />
                </div>
              )}
              <div className="notes">
                <FormLabel>Comments</FormLabel>
                <FormControl
                  as="textarea"
                  disabled={true}
                  className="height-class-input"
                  value={holidayIndividualdata?.notes}
                />{" "}
              </div>
              <div className="total-hours">
                <FormLabel>Created on</FormLabel>
                <FormControl
                  type="text"
                  placeholder=""
                  disabled={true}
                  value={holidayIndividualdata?.created_date}
                />
              </div>{" "}
              <div className="total-hours">
                <FormLabel>Created by</FormLabel>
                <FormControl
                  type="text"
                  placeholder=""
                  disabled={true}
                  value={holidayIndividualdata?.created_by}
                />
              </div>
            </Form>
          </div>
        </Modal.Body>
        {groupId === 5 || groupId === 8 ? null : (
          <>
            {editAddEntry && (
              <Modal.Footer className="modal-detail-main-wrapper">
                {holidayIndividualdata.status !== "Denied" ? (
                  <button
                    className="approve-btn-active"
                    onClick={btnDelLoading ? null : handleHolidayUpdate}
                  >
                    {btnDelLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "EDIT & SAVE"
                    )}
                  </button>
                ) : null}
              </Modal.Footer>
            )}
          </>
        )}
      </Modal>
    </div>
  );
}
