/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./style.scss";
import { postData, getRevertUser, baseURL, getCurrentUser } from "../../api";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { Button } from "react-bootstrap";
import Buttons from "../../components/Form/Button";
import { Col, Row, Container, Card, Modal } from "react-bootstrap";
import OnboardHeading from "../../components/OnboardHeading";
import ServerSideTable from "../../components/ServerSideTable";
import FormModal from "../../components/FormModal";
import AlertIcon from "../../assets/images/alert.svg";
import revertIcon from "../../assets/images/fa_refresh.svg";
import { Markup } from "interweave";
import payrollIcon from "../../assets/images/payroll_icon.svg";
import payrollInfoIcon from "../../assets/images/payroll_info.svg";
import backIcon from "../../assets/images/back_icon.svg";
import errorIcon from "../../assets/images/errorIcon.svg";
import downloadIcon from "../../assets/images/download_icon.svg";
import RunPayroll from "./runPayroll";
import moment from "moment";
import ApiLoader from "../../components/Loader/apiLoader";
import { type } from "@testing-library/user-event/dist/type";
import NotificationIcon from "../../assets/images/dashboard/notification_icon.svg";
import UpArrowIcon from "../../assets/images/dashboard/arrow_outward.svg";
import CheckCircle from "../../assets/images/dashboard/check_circle.svg";
import ManagePop from "../../assets/images/dashboard/manage-pop-icon.svg";
import CloseIcon from "../../assets/images/dashboard/close.svg";
import PayProfileIcon from "../../assets/images/payroll/payroll_profile.svg";
import PayrollInfo from "./payrollInfo";
import RunPayRollInfo from "./runPayrollInfo";

const Payroll = () => {
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState("test");
  const [showWaitModal, setShowWaitModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorWaitingModal, setShowErrorWaitingModal] = useState(false);
  // const [firstPayroll, setFirstPayroll] = useState();
  const [runPayrollLink, setRunPayrollLink] = useState("");
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizeperPage] = useState(25);
  const [totalSize, setTotalSize] = useState(0);
  const [processPayrollList, setProcessPayrollList] = useState([]);
  const [completePayrollData, setCompletePayrollData] = useState({});
  const [auto_refresh, setAuto_refresh] = useState(false);
  const [errSync, setErrSync] = useState(false);
  const [payrollErrors, setPayrollErrors] = useState("");
  const [apiStages, setApiStages] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [managePtoData, setManagePtoData] = useState({});
  const [flowType, setFlowType] = useState("");
  const [individualInfoText, setIndividualInfoText] = useState({
    regular: false,
    offCycle: false,
  });

  const getProcessPayrollList = async (
    page,
    sizePerPage,
    search,
    apiData,
    validationType
  ) => {
    setLoading(true);
    let obj = {
      page: page,
      page_size: sizePerPage,
      search: search,
      stage: apiData,
      validation_type: validationType,
      flow_type: "run_payroll",
    };
    try {
      const res = await postData("get-payroll-history-new", {}, obj);
      if (res?.status === true) {
        setProcessPayrollList(res?.data);
        setTotalSize(res?.count);
        setCompanyName(res?.company_name);
        setLoading(false);
        setCompletePayrollData(res);
        setAuto_refresh(res?.auto_refresh);
      } else {
        setLoading(false);
        toast.error(res?.message, { theme: "colored" });
        setShowErrorModal(true);
      }
    } catch (err) {}
  };

  const downloadReport = async (row) => {
    try {
      // Define your payload data
      const payloadData = {
        payroll_uuid: row.payroll_uuid,
        client_id: row.client_id,
      };

      // Make a POST request with the payload
      const response = await fetch(`${baseURL}payroll-process-report`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCurrentUser()}`,
        },
        body: JSON.stringify(payloadData),
      });

      if (!response.ok) {
        throw new Error("Failed to download file");
      }

      // Extract the filename from the Content-Disposition header
      // const filename = response.headers.get('Content-Disposition').split('filename=')[1];
      const filename = "report";
      // Convert the response to a blob
      const blob = await response.blob();

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element and trigger a click to download the file
      const link = document.createElement("a");
      link.href = url;
      link.download = filename || "downloadedFile.txt";
      document.body.appendChild(link);
      link.click();

      // Cleanup: remove the temporary link and URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  const handleRevertUser = () => {
    window.open(getRevertUser(), "_self");
  };

  const handleShowWaitModalOpen = () => {
    setShowWaitModal(true);
  };
  const handleShowWaitModalClose = () => {
    setShowWaitModal(false);
  };

  const handleShowErrorModalOpen = () => {
    setShowErrorModal(true);
  };
  const handleShowErrortModalClose = () => {
    setShowErrorModal(false);
    setErrSync(false);
  };

  const handleShowErrorWaitingtModalOpen = () => {
    setShowErrorWaitingModal(true);
  };
  const handleShowErrorWaitingtModalClose = () => {
    setShowErrorWaitingModal(false);
  };

  const onFilter = (page, sizePerPage, search) => {
    setPage(page);
    setSizeperPage(sizePerPage);
    getProcessPayrollList(page, sizePerPage, search, "history", "yes");
  };
  const payrollReportFormatter = (cell, row) => {
    return (
      <div>
        {row.processed && (
          <img
            src={downloadIcon}
            alt="appraisalIcon"
            className="cursor-pointer"
            onClick={() => {
              downloadReport(row);
            }}
          />
        )}
      </div>
    );
  };
  const columns = [
    {
      dataField: "sl.no",
      text: "ID",
      headerStyle: { width: "5%", textAlign: "left" },
      style: { textAlign: "left" },
      formatter: (cell, row, rowIndex, formatExtraData) => rowIndex + 1,
    },
    {
      dataField: "pay_period",
      text: "Start Date",
      formatter: (cell) => {
        const startDate = moment(cell.start_date, "YYYY-MM-DD").format(
          "MM-DD-YYYY"
        );
        return startDate;
      },
      // headerStyle: { width: "20%", textAlign: "center" },
      // style: { textAlign: "center" },
    },
    {
      dataField: "pay_period",
      text: "End Date",
      formatter: (cell) => {
        const endDate = moment(cell.end_date, "YYYY-MM-DD").format(
          "MM-DD-YYYY"
        );
        return endDate;
      },
      // headerStyle: { width: "20%", textAlign: "center" },
      // style: { textAlign: "center" },
    },
    {
      dataField: "check_date",
      text: "Processed Date",
      // headerStyle: { width: "20%", textAlign: "center" },
      // style: { textAlign: "center" },
      formatter: (cell) => {
        const formattedDate = moment(cell, "DD-MM-YYYY HH:mm:ss").format(
          "MM-DD-YYYY"
        );
        return formattedDate;
      },
    },
    {
      dataField: "processed",
      text: "Status",
      // headerStyle: { width: "20%", textAlign: "center" },
      // style: { textAlign: "center" },
      formatter: (cell) => {
        const processStatus = cell ? "Processed" : "Not Processed";
        return processStatus;
      },
    },
    {
      dataField: "is_off_cycle",
      text: "Cycle Type",
      formatter: (cell) => {
        const processStatus = cell ? "Off cycle" : "Regular cycle";
        return processStatus;
      },
    },
    {
      dataField: "payroll_report",
      text: "Report",
      // headerStyle: { width: "20%", textAlign: "center" },
      // style: { textAlign: "center" },
      formatter: payrollReportFormatter,
    },
  ];

  const trueEntries = processPayrollList.filter(
    (item) => item.is_off_cycle === true
  );
  const falseEntries = processPayrollList.filter(
    (item) => item.is_off_cycle === false
  );

  const lastOffCycleEnty = trueEntries[trueEntries.length - 1];
  const lastRegularCycleEntry = falseEntries[falseEntries.length - 1];

  useEffect(() => {
    getProcessPayrollList(page, sizePerPage, "", "history", "yes");
  }, []);

  const handleStageChange = (flow_type) => {
    // setFlowType(flow_type);
    setApiStages("process", flow_type);
    setShowWaitModal(true);
    getAutoRefreshProcessPayrollList(
      page,
      sizePerPage,
      "",
      "process",
      "yes",
      flow_type
    );
  };
  // const handleModalStageChange = () => {
  //   setApiStages("process");
  //   setShowWaitModal(true);
  //   setShowModal(false);
  //   getAutoRefreshProcessPayrollList(page, sizePerPage, "", "process", "yes");
  // };
  const getAutoRefreshProcessPayrollList = async (
    page,
    sizePerPage,
    search,
    apiData,
    validationType,
    flowType
  ) => {
    setLoading(true);
    setFlowType(flowType);
    let obj = {
      page: page,
      page_size: sizePerPage,
      search: search,
      stage: apiData,
      validation_type: validationType,
      flow_type: flowType,
    };
    try {
      const res = await postData("get-payroll-history-new", {}, obj);
      if (res?.status === true) {
        setAuto_refresh(res?.auto_refresh);
        setCompletePayrollData(res);
        if (res?.is_pto_payweek === true) {
          setShowModal(true);
          setManagePtoData(res.data);
        }
        if (res?.auto_refresh === false) {
          setRunPayrollLink(res?.flow_url);
          setShowWaitModal(false);
          setPayrollErrors(res?.onboarding_errors);
          if (!res?.isfirstpayroll && !res.hasOwnProperty("flow_url")) {
            setErrSync(true);
          } else setErrSync(false);
        }
        if (flowType === "run_payroll") {
          setIndividualInfoText({ regular: true, offCycle: false });
        } else if (flowType === "run_off_cycle_payroll") {
          setIndividualInfoText({ regular: false, offCycle: true });
        }
        setLoading(false);
      } else {
        toast.error(res?.message, { theme: "colored" });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (apiStages === "process") {
      const interval = setInterval(() => {
        if (auto_refresh) {
          getAutoRefreshProcessPayrollList(
            page,
            sizePerPage,
            "",
            "history",
            "yes",
            flowType
          );
        } else {
          clearInterval(interval);
        }
      }, 30000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [auto_refresh]);

  function convertTo12HourFormat(time) {
    const formattedTime = moment(time, "HH:mm:ss").format("hh:mm A");
    return formattedTime;
  }
  const backFunction = () => {
    if (completePayrollData?.data?.length !== 0) {
      getProcessPayrollList(page, sizePerPage, "", "history", "yes");
      setRunPayrollLink(null);
    } else {
      getProcessPayrollList(page, sizePerPage, "", "history", "yes");
      setRunPayrollLink(null);
    }
  };
  function formatDate(dateString) {
    const formattedDate = moment(dateString, "DD-MM-YYYY HH:mm:ss").format(
      "MM-DD-YYYY"
    );
    return formattedDate;
  }
  const handleModalCloseApi = () => {
    setShowModal(false);
    // setApiStages("process");
    setShowWaitModal(false);
    // getAutoRefreshProcessPayrollList(page, sizePerPage, "", "process", "no");
  };
  return (
    <>
      {!loading && (
        <Container fluid className="new_payroll">
          <div className="paystub-view">
            <Row>
              <Col md={getRevertUser() ? 10 : 12} sm={getRevertUser() ? 8 : 12}>
                <OnboardHeading title="Payroll" subtitle={companyName} />
              </Col>
              {getRevertUser() ? (
                <Col
                  md={2}
                  sm={getRevertUser() ? 4 : 2}
                  className="d-flex align-items-center justify-content-end mb-4 mb-md-0"
                >
                  <div className="revert">
                    <Button
                      variant="danger"
                      className="revert_btn"
                      onClick={handleRevertUser}
                    >
                      <img
                        src={revertIcon}
                        alt="revert"
                        className="revert-img me-1"
                      />
                      Revert User
                    </Button>
                  </div>
                </Col>
              ) : (
                ""
              )}
            </Row>
            {runPayrollLink && !auto_refresh && (
              <button
                className="d-flex mb-4 align-items-center back_btn"
                onClick={backFunction}
              >
                <img src={backIcon} alt="back_icon" className="back_icon" />
                <p className="m-0 p-0 f-16 fw-600">Back</p>
              </button>
            )}
            {completePayrollData?.onboarding_errors ? (
              <div className=" alert-card d-flex align-items-center flex-column justify-content-center">
                {/* <div className="alert-round">
                  <img
                    src={AlertIcon}
                    className="alert-icon"
                    alt="alert-icon"
                  />
                </div> */}
                <p className="alert-text m-0 p-0">Payroll Alert!</p>
                <hr className="alert-hr" />
                <div
                  dangerouslySetInnerHTML={{
                    __html: completePayrollData?.onboarding_errors,
                  }}
                ></div>
              </div>
            ) : completePayrollData?.isfirstpayroll ||
              (completePayrollData?.isfirstpayroll === false &&
                completePayrollData?.data?.length === 0 &&
                Object.keys(completePayrollData.payroll_logs_data).length !==
                  0 &&
                runPayrollLink === "") ||
              (completePayrollData?.isfirstpayroll === false &&
                completePayrollData?.data?.length === 0 &&
                Object.keys(completePayrollData.payroll_logs_data).length !==
                  0 &&
                runPayrollLink === null) ||
              (completePayrollData?.data?.length === 0 && auto_refresh) ? (
              <>
                {completePayrollData?.isfirstpayroll === false &&
                completePayrollData?.data?.length === 0 &&
                Object.keys(completePayrollData.payroll_logs_data).length !==
                  0 ? (
                  <div className="prepare-new-payroll-wrapper">
                    <div className="payroll-title-wrapper">
                      <img src={PayProfileIcon} alt="pay-record" />
                      <h2>PREPARE PAYROLL</h2>
                    </div>
                    <div className="prepare-run-wrapper prepare-run-padding-wrapper">
                      <div className="prepare-run-sub-wrapper">
                        <div className="prepare-content-wrapper">
                          <div className="w-75">
                            <div className="prepare-run-text-wrapper">
                              <h3>Regular cycle payroll</h3>
                              <p>
                                These are processed according to the scheduled
                                pay periods for the selected pay frequency,
                                ensuring employees are paid consistently and on
                                time.
                              </p>
                            </div>
                            <div className="prepare-run-text-wrapper">
                              <h3>Here’s what happens when you run payroll:</h3>
                              <ul>
                                <li>
                                  Employee hours and PTO are automatically
                                  pulled from timekeeping (if enabled).
                                </li>
                                <li>
                                  Salaries, taxes, and deductions are calculated
                                  accurately.
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="prepare-run-btn-wrapper">
                            <Button
                              type="submit"
                              className="btn-primary text-white prepare-btn"
                              disabled={loading || auto_refresh}
                              loading={loading}
                              onClick={() => handleStageChange("run_payroll")}
                            >
                              PREPARE PAYROLL
                            </Button>
                          </div>
                        </div>
                        {auto_refresh && individualInfoText.regular ? (
                          <div className="waiting-text payroll-info-container">
                            <img
                              src={payrollInfoIcon}
                              alt="payroll_icon"
                              className="me-2"
                            />
                            <p>
                              Payroll is processing. Please don’t refresh your
                              screen. This may take a minute.
                            </p>
                          </div>
                        ) : null}
                      </div>
                      <div className="prepare-run-sub-wrapper">
                        <div className="prepare-content-wrapper">
                          <div className="w-75">
                            <div className="prepare-run-text-wrapper">
                              <h3>Off cycle payroll</h3>
                              <p>
                                These are processed outside the regular payroll
                                cycle, allowing you to handle payments for
                                specific dates and employees, such as bonuses,
                                corrections, or special payouts.
                              </p>
                            </div>
                            <div className="prepare-run-text-wrapper">
                              <h3>
                                Here’s what happens when you run an off cycle
                                payroll:
                              </h3>
                              <ul>
                                <li>
                                  Select employees and enter the payment details
                                  for this special payroll run based on the
                                  payment type.
                                </li>
                                <li>
                                  Payroll is processed separately from the
                                  regular cycle
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="prepare-run-btn-wrapper">
                            <Button
                              type="submit"
                              className="btn-primary text-white prepare-btn"
                              disabled={loading || auto_refresh}
                              loading={loading}
                              onClick={() =>
                                handleStageChange("run_off_cycle_payroll")
                              }
                            >
                              PREPARE PAYROLL
                            </Button>
                          </div>
                        </div>
                        {auto_refresh && individualInfoText.offCycle ? (
                          <div className="waiting-text payroll-info-container">
                            <img
                              src={payrollInfoIcon}
                              alt="payroll_icon"
                              className="me-2"
                            />
                            <p>
                              Payroll is processing. Please don’t refresh your
                              screen. This may take a minute.
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div>
                      <Markup content={payrollErrors} />
                    </div>

                    {!auto_refresh ? (
                      <div className="heading f-18 w-100 text-center pb-3 text-primary">
                        Your payroll is successfully prepared. You can proceed
                        to process and run it now.
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="prepare-new-payroll-wrapper">
                    <div className="payroll-title-wrapper">
                      <img src={PayProfileIcon} alt="pay-record" />
                      <h2>PREPARE PAYROLL</h2>
                    </div>
                    <div className="prepare-run-wrapper prepare-run-padding-wrapper">
                      <div className="prepare-run-sub-wrapper">
                        <div className="prepare-content-wrapper">
                          <div className="w-75">
                            <div className="prepare-run-text-wrapper">
                              <h3>Regular cycle payroll</h3>
                              <p>
                                These are processed according to the scheduled
                                pay periods for the selected pay frequency,
                                ensuring employees are paid consistently and on
                                time.
                              </p>
                            </div>
                            <div className="prepare-run-text-wrapper">
                              <h3>Here’s what happens when you run payroll:</h3>
                              <ul>
                                <li>
                                  Employee hours and PTO are automatically
                                  pulled from timekeeping (if enabled).
                                </li>
                                <li>
                                  Salaries, taxes, and deductions are calculated
                                  accurately.
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="prepare-run-btn-wrapper">
                            <Button
                              type="submit"
                              className="btn-primary text-white prepare-btn"
                              disabled={loading || auto_refresh}
                              loading={loading}
                              onClick={() => handleStageChange("run_payroll")}
                            >
                              PREPARE PAYROLL
                            </Button>
                          </div>
                        </div>
                        {auto_refresh && individualInfoText.regular ? (
                          <div className="waiting-text payroll-info-container">
                            <img
                              src={payrollInfoIcon}
                              alt="payroll_icon"
                              className="me-2"
                            />
                            <p>
                              Payroll is processing. Please don’t refresh your
                              screen. This may take a minute.
                            </p>
                          </div>
                        ) : null}
                      </div>
                      <div className="prepare-run-sub-wrapper">
                        <div className="prepare-content-wrapper">
                          <div className="w-75">
                            <div className="prepare-run-text-wrapper">
                              <h3>Off cycle payroll</h3>
                              <p>
                                These are processed outside the regular payroll
                                cycle, allowing you to handle payments for
                                specific dates and employees, such as bonuses,
                                corrections, or special payouts.
                              </p>
                            </div>
                            <div className="prepare-run-text-wrapper">
                              <h3>
                                Here’s what happens when you run an off cycle
                                payroll:
                              </h3>
                              <ul>
                                <li>
                                  Select employees and enter the payment details
                                  for this special payroll run based on the
                                  payment type.
                                </li>
                                <li>
                                  Payroll is processed separately from the
                                  regular cycle
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="prepare-run-btn-wrapper">
                            <Button
                              type="submit"
                              className="btn-primary text-white prepare-btn"
                              disabled={loading || auto_refresh}
                              loading={loading}
                              onClick={() =>
                                handleStageChange("run_off_cycle_payroll")
                              }
                            >
                              PREPARE PAYROLL
                            </Button>
                          </div>
                        </div>
                        {auto_refresh && individualInfoText.offCycle ? (
                          <div className="waiting-text payroll-info-container">
                            <img
                              src={payrollInfoIcon}
                              alt="payroll_icon"
                              className="me-2"
                            />
                            <p>
                              Payroll is processing. Please don’t refresh your
                              screen. This may take a minute.
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div>
                      <Markup content={payrollErrors} />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {runPayrollLink ? (
                  <RunPayRollInfo
                    auto_refresh={auto_refresh}
                    processPayrollList={processPayrollList}
                    lastRegularCycleEntry={lastRegularCycleEntry}
                    lastOffCycleEnty={lastOffCycleEnty}
                    loading={loading}
                    handleStageChange={handleStageChange}
                    completePayrollData={completePayrollData}
                    individualInfoText={individualInfoText}
                    runPayrollLink={runPayrollLink}
                  />
                ) : (
                  <PayrollInfo
                    auto_refresh={auto_refresh}
                    processPayrollList={processPayrollList}
                    lastRegularCycleEntry={lastRegularCycleEntry}
                    lastOffCycleEnty={lastOffCycleEnty}
                    loading={loading}
                    handleStageChange={handleStageChange}
                    completePayrollData={completePayrollData}
                    individualInfoText={individualInfoText}
                    runPayrollLink={runPayrollLink}
                  />
                )}
                {runPayrollLink ? (
                  <RunPayroll
                    name={"Run Payroll"}
                    runPayrollLink={runPayrollLink}
                  />
                ) : (
                  <>
                    <h4 className="payroll-add-title my-2">Process History </h4>
                    <div className="process-payroll my-3">
                      <ServerSideTable
                        columns={columns}
                        data={processPayrollList.slice().reverse()}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={totalSize}
                        onFilter={onFilter}
                        loading={loading}
                      ></ServerSideTable>
                    </div>
                  </>
                )}
              </>
            )}
            {/* modal contents */}
            {!showModal && (
              <>
                {showWaitModal && (
                  <FormModal
                    heading=""
                    show={showWaitModal}
                    size="md"
                    onClose={handleShowWaitModalClose}
                    backdrop="static"
                    modalClassName="personal_container_modal waiting_modal"
                  >
                    <Row>
                      <Col md={12}>
                        <div className="showWait">
                          {/* <div>
                        <img src={loadingIcon} alt="wait" />
                      </div> */}
                          <ApiLoader />

                          <div>Payroll is processing. </div>
                          <div>
                            Please don’t refresh your screen. This may take a
                            minute.
                          </div>
                          <Buttons
                            type="button"
                            className="btn-primary text-white prepare-btn my-3"
                            onClick={() => handleShowWaitModalClose()}
                          >
                            OK
                          </Buttons>
                        </div>
                      </Col>
                    </Row>
                  </FormModal>
                )}
              </>
            )}
            {/* error prepare btn modal */}
            {(showErrorModal || errSync) && (
              <FormModal
                heading=""
                show={showErrorModal || errSync}
                size="md"
                onClose={handleShowErrortModalClose}
                backdrop="static"
                modalClassName="personal_container_modal waiting_modal"
              >
                <Row>
                  <Col md={12}>
                    <div className="show-error-conetnt">
                      <div>
                        <img
                          src={errorIcon}
                          alt="payroll_icon"
                          className="me-2"
                        />
                      </div>
                      <div className="py-3">
                        {errSync
                          ? "An error occurred while processing the payroll. Kindly attempt the operation again. "
                          : "An error occurred while processing the payroll. Kindly attempt the operation again."}
                      </div>
                    </div>
                  </Col>
                  <Col md={12} className="text-center my-3">
                    <Button
                      type="button"
                      className="btn-default text-blacksix me-3 cancel-payroll"
                      onClick={handleShowErrortModalClose}
                    >
                      {errSync ? "Close" : "Cancel"}
                    </Button>
                    {showErrorModal && (
                      <Button
                        type="submit"
                        className="btn-primary text-white prepare-btn"
                        disabled={loading || auto_refresh}
                        loading={loading}
                      >
                        Prepare payroll
                      </Button>
                    )}
                  </Col>
                </Row>
              </FormModal>
            )}

            {/* error wait modal */}
            {showErrorWaitingModal && (
              <FormModal
                heading=""
                show={showErrorWaitingModal}
                size="md"
                onClose={handleShowErrorWaitingtModalClose}
                backdrop="static"
                modalClassName="personal_container_modal waiting_modal"
              >
                <Row>
                  <Col md={12}>
                    <div className="show-error-conetnt">
                      <div>
                        <img
                          src={errorIcon}
                          alt="payroll_icon"
                          className="me-2"
                        />
                      </div>
                      <div className="py-3">
                        {" "}
                        An error occurred while processing the payroll. Kindly
                        attempt the operation again.
                      </div>
                    </div>
                  </Col>
                  <Col md={12} className="text-center">
                    <Buttons
                      type="button"
                      className="btn-primary text-white prepare-btn my-3"
                      onClick={() => handleShowErrorWaitingtModalClose()}
                    >
                      OK
                    </Buttons>
                  </Col>
                </Row>
              </FormModal>
            )}
            {/* <div className="payroll-gusto-pto-modal-wrapper"> */}
            <Modal
              show={showModal}
              onHide={() => setShowModal(false)}
              className="modal-email-wrapper payroll-gusto-pto-modal-wrapper"
            >
              <Modal.Header className="modal-manage-header">
                <h3>Manage pending requests!</h3>
                <img
                  src={CloseIcon}
                  alt="close-icon"
                  onClick={handleModalCloseApi}
                />
              </Modal.Header>
              <Modal.Body className="manage-payroll-modal">
                <p>
                  To ensure accurate PTO balances, please manage the pending
                  time off requests before processing payroll
                </p>
                <div className="manage-pop-content">
                  <div className="manage-pop-sub-wrapper">
                    <div className="manage-pop-title">
                      <img src={ManagePop} alt="manage-pop" />
                      <h5>Manage time card request</h5>
                    </div>
                    {managePtoData?.is_timecard === true ? (
                      <div className="manage-pop-incomplete">
                        <a href="/multi-card-approval">
                          <h5>Go to time keeping</h5>
                          <img src={UpArrowIcon} alt="check-circle" />
                        </a>
                      </div>
                    ) : (
                      <div className="manage-pop-completed">
                        <h5>Completed</h5>
                        <img src={CheckCircle} alt="check-circle" />
                      </div>
                    )}
                  </div>
                  <div className="manage-pop-sub-wrapper">
                    <div className="manage-pop-title">
                      <img src={ManagePop} alt="manage-pop" />
                      <h5>Manage time off request</h5>
                    </div>
                    {managePtoData?.is_pto === true ? (
                      <div className="manage-pop-incomplete">
                        <a href="/time-off-calendar">
                          <h5>Go to time off</h5>
                          <img src={UpArrowIcon} alt="check-circle" />
                        </a>
                      </div>
                    ) : (
                      <div className="manage-pop-completed">
                        <h5>Completed</h5>
                        <img src={CheckCircle} alt="check-circle" />
                      </div>
                    )}
                  </div>
                </div>
              </Modal.Body>
              {/* <Modal.Footer className="modal-payroll-manage-footer">
                <Button
                  variant="primary"
                  onClick={
                    managePtoData?.is_pto === true ||
                    managePtoData?.is_timecard === true
                      ? null
                      : handleModalStageChange
                  }
                  className="modal-manage-payroll-button"
                  disabled={
                    managePtoData?.is_pto === true ||
                    managePtoData?.is_timecard === true
                  }
                >
                  PREPARE PAYROLL
                </Button>
              </Modal.Footer> */}
            </Modal>
          </div>
          {/* </div> */}
        </Container>
      )}
      {loading && <Loader />}
    </>
  );
};

export default Payroll;
